<template>
  <div class="hello">
    <div class="landing">
      <video src="../assets/cover.mp4" poster="../assets/cover.jpeg" type="video/mp4"
             autoplay loop></video>
      <div class="landing-overlay">

        <div class="landing-inner">
          <h1>The best Hvar Villas</h1>
        </div>
      </div>
    </div>
    <div class="landing-punch-text">
      <p>If you're looking for a luxurious vacation destination, look no further than the picturesque island of Hvar.
        Known for its stunning coastline, vibrant nightlife, and beautiful beaches, Hvar is a must-visit destination for
        those seeking the ultimate Mediterranean experience.
      </p>
      <p>
        One of the best ways to experience Hvar is by renting a villa with a pool. Hvar villas offer the perfect
        combination of luxury, privacy, and convenience, allowing you to relax and enjoy all that this beautiful island
        has to offer.
      </p>
      <!--<p>

        You will get full 𝟐𝟒/𝟕 concierge service, our professional cleaners will keep the area spotlessly clean,
        and
        the 𝐬𝐩𝐞𝐞𝐝 𝐛𝐨𝐚𝐭𝐬 will be ready for 𝐚𝐢𝐫𝐩𝐨𝐫𝐭 𝐭𝐫𝐚𝐧𝐬𝐟𝐞𝐫 or wonderful 𝐝𝐚𝐢𝐥𝐲
        𝐭𝐫𝐢𝐩.⁣⁣⁣
        <br>The fact that we are from Hvar and have great contacts will make you feel very welcome,
        𝐚𝐥𝐰𝐚𝐲𝐬 𝐠𝐞𝐭 𝐦𝐨𝐫𝐞, and have a wonderful vacation.
      </p>-->
      <div class="landing-punch-underline"></div>
    </div>
    <div class="details">
      <img src="../assets/jetski.jpg" alt="Hvar Holidays" class="details-image">
      <div class="details-text">
        <h2>Concierge service</h2>
        <p class="details-line"></p>
        <h3 class="details-subtitle">Unique key of care</h3>
        <p>We know time is the most important resource. That is why you will be exclusively dedicated to quality
          time
          spent with family and friends.
          <br>To make everything as you wish, there is <strong>24/7 guest service</strong>.
        </p>
        <div class="details-list">
          <div class="details-list-inner">
            <li>Private Jet Transfers</li>
            <li>Airport Transfers</li>
            <li>Private Tours</li>
            <li>Rent a Boat</li>
          </div>
          <div class="details-list-inner">
            <li>Dining & Nightlife</li>
            <li>Catering Service</li>
            <li>Private Chef</li>
            <li>VIP Security</li>
          </div>
          <div class="details-list-inner">
            <li>Fitness Trainer</li>
            <li>Activity & Sports</li>
            <li>Wellness & Spa</li>
            <li>Rent a car</li>

          </div>
        </div>
        <router-link :to="{name: 'concierge'}">
          <button class="button-details">Read More</button>
        </router-link>
      </div>

    </div>
    <div class="villas-list">
      <div class="villas-list-text">
        <h2 class="villas-list-title">Hvar villas collection</h2>
        <p class="villas-text-line"></p>
        <p class="villas-description">Villa with pool or with a beautiful garden overlooking the sea,
          in the Hvar Old Town or near the sea? Choice is yours. <strong>Airport transfer with speed boat on your
            arrival date is included.</strong>
          Imagine your ideal vacation, leave everything to our team and immerse yourself into Hvar magic.</p>
        <div class="villas-buttons">
          <router-link :to="{name: 'OldTownVillas'}">
            <button class="villas-button villas-readmore">Read more</button>
          </router-link>
          <button class="villas-button villas-enquiry" @click="show()">Send enquiry</button>
        </div>
      </div>
      <img src="../assets/Brooks.jpg" alt="rent luxury villa Hvar" class="villas-image">
    </div>
    <div class="reviews">
      <h2>Reviews</h2>
      <div class="reviews-inner">
        <ReviewSwipe/>
      </div>
    </div>
  </div>
</template>

<script>
import indexData from '../data/services.json'
import houseDetails from '../data/villaDetails.json'
import Modal from "@/components/Modal"
import ReviewSwipe from "@/components/ReviewSwipe";


export default {
  name: 'Index',
  components: {ReviewSwipe},
  metaInfo() {
    return {
      title: 'The Best 2023. Hvar Villas | Amazing Hvar Holidays ',
      meta: [
        {name: "robots", content: "index,follow"},
        {
          name: 'description',
          content: 'Book luxury Hvar villas or villa with pool in Hvar town directly from the host! With 24/7guest and concierge service and Split Hvar airport transfer included. Have an amazing Hvar holidays!'
        }

      ],
      link: [
        {rel: 'favicon', href: 'favicon.ico'}
      ],
      script: [{
        type: 'application/ld+json',
        json: {
          "@context": "http://schema.org",
          "@type": [
            "LodgingBusiness",
          ],
          "image": "../static/images/logo.png",
          "@id": "https://www.hvar.house",
          "name": "Hvar House",
          "description": "Book luxury villa or villa with pool in Hvar town dirrectly with the host! With 24/7guest and concierge service and Split Hvar airport transfer included.",
          "openingHours": [
            "Mo-Fr 09:00-23:00",
            "Sa 09:00-23:00",
            "Su 09:00-23:00"
          ],
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 43.171989,
            "longitude": 16.441758
          },

          "priceRange": "$$$",
          "currenciesAccepted": "HRK",
          "paymentAccepted": "Cash, Credit Card",
          "url": "https://www.hvar.house",
          "telephone": "+385918963726",
          "sameAs": [
            "https://www.facebook.com/hvarhouse",
            "https://www.instagram.com/hvarhouse"
          ],
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Hvar",
            "addressRegion": "Splitsko-Dalmatinska",
            "postalCode": "21450",
            "streetAddress": "Jurja Matijevića 17"
          },
          "review": {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5"
            },
            "author": {
              "@type": "Person",
              "name": "Kurtis "
            }
          },
          "email": "sanja@hvar.house"
        }
      }]
    }
  },

  props: {
    msg: String
  },
  data() {
    return {
      details: houseDetails,
      myData: indexData,
      loaded: false,
    }
  },
  methods: {
    onLoaded() {
      this.loaded = true;
    },

    show() {
      this.$modal.show(
          Modal
      );
    },
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

