<template>
  <div id="app">
    <Navigation/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Navigation from './components/Navigation'
import Footer from './components/Footer'

export default {
  name: 'App',
  components: {
    Navigation,
    Footer
  }
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
#app {
  font-family: 'Arapey', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  background-color: rgba(243,223,208,.2);
}



@font-face {
  font-family: 'Medio';
  font-style: normal;
  font-weight: normal;
  src: local('Medio'), url('./fonts/Medio.woff') format('woff');
}
@font-face {
  font-family: 'FreightBigProBook-Regular';
  src: local('FreightBigProBook-Regular'),
  url('./fonts/FreightBigProBook.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}






</style>

