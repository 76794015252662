<template>
  <div class="contact-modal">
    <div class="modal-inner">
      <h1>Contact us</h1>
      <div v-if="errors.length">
        <b>Please correct the following error(s):</b>
        <ul>
          <li v-for="error in errors" :key="error.id">{{ error }}</li>
        </ul>
      </div>
      <form @submit="checkForm">
        <label>

          <input type="text" v-model="name" name="name" class="name" placeholder="Name">
        </label>
        <label>

          <input type="text" v-model="email" name="email" class="email" placeholder="Email">
        </label>
        <label>

          <input type="text" v-model="title" name="title" class="title" placeholder="Title" >

        </label>
        <label>

          <textarea type="text" v-model="message" name="message" class="content" placeholder="Your Question"></textarea>
        </label>
        <button type="submit">Send</button>
      </form>


    </div>

  </div>
</template>

<script>
import emailjs from 'emailjs-com'

export default {
  props:['title'],
  data() {
    return {
      name: '',
      email: '',
      message: '',
      errors: [],


    }
  },
  methods: {
    checkForm(e) {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required.");
      }
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.title) {
        this.errors.push("Title required.");
      }
      if (!this.message) {
        this.errors.push("Message required.");
      }

      if (!this.errors.length) {
        try {
          emailjs.sendForm('service_2np7tvi', 'template_qvub64i', e.target,
              'user_s0LGqr12GO77XIX5ZWvyK', {
                name: this.name,
                email: this.email,
                title: this.title,
                message: this.message
              })

        } catch (error) {
          console.log({error})
        }
        this.name = ''
        this.email = ''
        this.title = ''
        this.message = ''
        this.$modal.hideAll()
      }

      e.preventDefault();
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendEmail(e) {
      try {
        emailjs.sendForm('service_2np7tvi', 'template_qvub64i', e.target,
            'user_s0LGqr12GO77XIX5ZWvyK', {
              name: this.name,
              email: this.email,
              title: this.title,
              message: this.message
            })

      } catch (error) {
        console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.title = ''
      this.message = ''
      this.$modal.hideAll()
    },


  }

}
</script>