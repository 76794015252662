<template>
  <carousel :responsive="{0:{items:1, nav:false}, 480:{items:1, nav:false}, 770:{
    items:2, nav:false
  }, 1000:{items:3, nav:true}}">


    <div class="reviews">
      <div class="stars">
        <img src="../assets/stars.svg" alt="">
      </div>
      <p><q>Our host Sanja was soo funny and friendly upon and made us feel right at home, showing, and marking, on a
        map
        the best places to go for whatever we needed..felt as though we had been there already after speaking to her and
        she went above and beyond to make sure our stay was the best it could be! </q></p>
      <p class="reviewer">Kurtis</p>
    </div>
    <div class="reviews">
      <div class="stars">
        <img src="../assets/stars.svg" alt="">
      </div>
      <p><q id="sarah">Sanja was our point of contact while we where there and she is amazing. She helped organize a
        great boat trip
        for the day, was also available to help and was great at communication. She is also full of really great
        recommendations. Absolute made the trip for us. I absolutely would return here in a heartbeat</q></p>
      <p class="reviewer">Sarah</p>
    </div>
    <div class="reviews">
      <div class="stars">
        <img src="../assets/stars.svg" alt="">
      </div>
      <p><q id="harriet">Sanja was the most wonderful host! She was so helpful with making recommendations about the
        area, everything from the boat trips to restaurants and beach clubs. The villa is absolutely stunning: great
        bedrooms and lovely, clean bathrooms with great showers. The villa made it such a fantastic holiday!</q></p>
      <p class="reviewer">Harriet</p>
    </div>
    <div class="reviews">
      <div class="stars">
        <img src="../assets/stars.svg" alt="">
      </div>
      <p><q id="jane">Ideas for dates out and the pick of the best were happily supplied by our amazing host Sanja.
        Such a joy to chat with, i'll miss her and this wonderful spot.</q></p>
      <p class="reviewer">Jane</p>
    </div>
    <div class="reviews">
      <div class="stars">
        <img src="../assets/stars.svg" alt="">
      </div>
      <p><q id="grant">Sanja was very friendly and helpful and gave us very good recommendations for restaurants, bars,
        and boat
        trips. We felt like home</q>̵ </p>
      <p class="reviewer">Grant</p>
    </div>
    <div class="reviews">
      <div class="stars">
        <img src="../assets/stars.svg" alt="">
      </div>
      <p><q id="sarah2">Wonderful in every way! Beautiful villa, perfect location and great communication throughout
        our stay. Highly recommend!</q></p>
      <p class="reviewer">Sarah</p>
    </div>
    <div class="reviews">
      <div class="stars">
        <img src="../assets/stars.svg" alt="">
      </div>
      <p><q id="elodie">Sanja was very nice, she gave me a cake on my birthday day! And gave us a lot of good address to
        party, go to
        beach or even to the restaurant. I would again strongly recommend thank you!</q></p>
      <p class="reviewer">Elodie</p>
    </div>


  </carousel>
</template>

<script>

import carousel from 'vue-owl-carousel'

export default {
  components: {carousel},
  responsive: {
    // breakpoint from 0 up
    0: {
      items: 3,
      nav: false,
    },
    // breakpoint from 480 up
    480: {
      items: 1,
      nav: false,
    },
    // breakpoint from 768 up
    768: {
      items: 2,
      nav: false,
    }
  }
}

</script>