import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)


export const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import(/* webpackChunkName: "index" */ '../components/Index')

    },
    {
        path: '/old-town-villas',
        name: 'OldTownVillas',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "oldtownVillas" */ '../components/OldTownVillas')
    },
    {
        path: '/services',
        name: 'Services',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "services" */ '../components/Services')
    },
    {
        path: '/concierge',
        name: 'concierge',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "concierge" */ '../components/Concierge')
    },

    {
        path: '/private-tours',
        name: 'private tours',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "privateTours" */ '../components/PrivateTours')
    },

    {
        path: '/contact',
        name: 'contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "contact" */ '../components/Contact')
    },
    {
        path: '/details/:id',
        meta: {
            sitemap: {
                slugs: [
                    'villa-hvar-town-beauty',
                    'hvar-old-town-villa-fortica',
                    'hvar-town-villa-anabel',
                    'var-pakleni-islands-villa-perfect-escape',
                    'hvar-town-villa-modern-beauty'

                ]
            }
        },
        name: 'details',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "details" */ '../components/HouseDetails')
    },
    {
        path: '/about-us',
        name: 'Meet the team',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "meet the team" */ '../components/AboutUs')
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }

})

export default router
