import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import VCalendar from 'v-calendar';
import VModal from 'vue-js-modal'
import 'leaflet/dist/leaflet.css';
import { Icon } from "leaflet";
import VueAgile from 'vue-agile'
import VueMeta from 'vue-meta';
import Vuex from 'vuex'
import VueLazyload from 'vue-lazyload'
import theme from '@/style/style.css'

delete Icon.Default.prototype._getIconUrl;



Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

Vue.use(theme)

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(Vuex)
Vue.use(VueAgile)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VModal,{
  dynamicDefaults:{
    height: 'auto',
    styles:'background-color:#F3DFD0 '

  }
})

Vue.use(VueLazyload)

Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import router from './router'
new Vue({
  created () {
    AOS.init()
  },

  router,
  render: h => h(App)
}).$mount('#app')
